import React from "react"
import { graphql, Link } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./SuccessStories.module.css"
import altStyles from "./WhatWeDo.module.css"
import HubspotForm from "components/HubspotForm"

// eslint-disable-next-line
export const SuccessStoriesPage = ({ data, footerImage, divideImage, ...props }) => (
  <Layout {...props} {...data}>
    <Container className={`${altStyles.wrapper} ${styles.wrapper}`}>
      <div>
        <p className={altStyles.bannertitle}>{data.bannerTitle}</p>
        <Title className={`${altStyles.title} ${altStyles.titlesmall}`}>
          <span dangerouslySetInnerHTML={{ __html: data.title || ""}} />
        </Title>
        <Link className={altStyles.bannerbutton} to={`${data.bannerButtonUrl}`}>{data.bannerButtonText}</Link>
      </div>
      {data.customerBackground.video ? (
          <div className={styles.video}>
            <iframe
              src={data.customerBackground.video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={data.title}
            />
          </div>
      ) : data.image ? (
        <div className={altStyles.image}>
          <Img
            loading="lazy"
            objectFit="contain"
            key={data.title}
            alt={data.title}
            title={data.title}
            fluid={data.image.childImageSharp.fluid}
          />
        </div>
      ) : null}
    </Container>
    <div className={`${styles.gradientBackground} ${styles.customerBackground}`}>
      <Container className={styles.customerContentWrapper}>
        <TitleBlock className={styles.contentTitle} isWhite isLarger>Background</TitleBlock>
        <div className={styles.content}>
          {data.customerBackground && data.customerBackground.image ? (
            <Img 
            loading="lazy"
            className={styles.gridimage}
            objectFit="contain"
            alt={data.customerBackground.image.title}
            title={data.customerBackground.image.title}
            fluid={data.customerBackground.image.childImageSharp.fluid}
            />
          ) : null}
          {data.customerBackground && data.customerBackground.text ? (
            <div className={styles.textContent} dangerouslySetInnerHTML={{ __html: marked(data.customerBackground.text || "") }} />
          ) : null}
        </div>
      </Container>
    </div>
    <div>
      <Container className={styles.customerContentWrapper}>
        <TitleBlock className={styles.contentTitle}  isLarger>The Challenge</TitleBlock>
        <div className={styles.content}>
        {data.customerChallenge && data.customerChallenge.text ? (
          <div className={styles.textContent} dangerouslySetInnerHTML={{ __html: marked(data.customerChallenge.text || "") }} />
        ) : null}
        </div>
      </Container>
    </div>
    <div className={`${styles.grayBackground} ${styles.customerSolution}`}>
      <Container className={styles.customerContentWrapper}>
        <TitleBlock className={styles.contentTitle} isLarger>Our Solution</TitleBlock>
        <div className={styles.content}>
        {data.customerSolution && data.customerSolution.text ? (
          <div className={styles.textContent} dangerouslySetInnerHTML={{ __html: marked(data.customerSolution.text || "") }} />
        ) : null}
        </div>
      </Container>
    </div>
    <div className={styles.customerResult}>
      <Container className={styles.customerContentWrapper}>
        <TitleBlock className={styles.contentTitle} isLarger>The Result</TitleBlock>
        <div className={styles.content}>
        {data.customerBackground && data.customerBackground.text ? (
          <div className={styles.textContent} dangerouslySetInnerHTML={{ __html: marked(data.customerResult.text || "") }} />
        ) : null}
        </div>
      </Container>
    </div>
    {data.form && data.portalId ? (
      <div className={altStyles.grayBackground} id="contact-form">
        <Container>
        <div className={altStyles.titleContainer}>
            <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span><span dangerouslySetInnerHTML={{ __html: data.formTitle || ""}} /></TitleBlock>
          </div>
          <HubspotForm id={data.form} portalId={data.portalId} />
        </Container>
      </div>
    ) : null}
  </Layout>
)

// eslint-disable-next-line
export const SuccessStoriesPageWrapper = (props) => (
  <SuccessStoriesPage
    {...props}
    footerImage={props.data.footerImage}
    divideImage={props.data.divideImage}
    quoteImage={props.data.quoteImage}
    data={props.data.markdownRemark.frontmatter}
  />
)

export default SuccessStoriesPageWrapper

export const pageQuery = graphql`
  query SuccessStoriesPage($id: String) {
    footerImage: file(relativePath: { eq: "customers-footer-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 3643) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    divideImage: file(relativePath: { eq: "divide.png" }) {
      childImageSharp {
        fluid(maxWidth: 3643) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        id
        title
        description
        bannerTitle
        bannerButtonText
        bannerButtonUrl
        formTitle
        form
        portalId
        image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        logo {
          childImageSharp {
            fluid(maxWidth: 120, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        customerBackground {
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          title
          text
          video
        }
        customerChallenge {
          text
        }
        customerSolution {
          text
        }
        customerResult {
          text
        }
      }
    }
  }
`
